import { parseCookies } from 'nookies';
import { useMemo } from 'react';
import type { ProviderCasinoResApiInterface } from 'types/casino';
import ProviderCard from '~components/card/provider-card';
import HttpErrorHandler from '~components/http-error-handler';
import {
  DEFAULT_CURRENCY,
  ProviderTypeEnum,
  S3_BUCKET_IMAGES,
} from '~constants/etc';
import PageUrls from '~constants/page-urls';
import { useCasinos, useCasinosInfoHelper } from '~hooks/use-casinos';
import { useRouter } from '~hooks/use-router';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { encrypt } from '~libs/encrypt';
import objectToQueryString from '~libs/object-to-query-string';
import { ClientSideRender } from '~components/layouts/client-side-render';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';
import { useDialog } from '~hooks/use-dialog';

const CasinoProviderListsContainer: React.FC = () => {
  const router = useRouter();
  const { data: casinoProvider } = useCasinos();
  const { getCasinoProviderImage } = useCasinosInfoHelper();
  const [dialog, dialogContextHolder] = useDialog();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;

  const casinoProviderMapped = useMemo(() => {
    if (!casinoProvider) {
      return [];
    }
    return casinoProvider.map((provider) => {
      const images = getCasinoProviderImage(provider.id);
      return {
        ...provider,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
      };
    });
  }, [casinoProvider]);

  const handleOpenCasino = (game: ProviderCasinoResApiInterface): void => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      const queryString = objectToQueryString({
        category: ProviderTypeEnum.Casino,
        providerId: game.id,
        currency: currencyFiltered,
        walletId: walletInfo?.objectId,
      });
      router.push(`/${locale}${PageUrls.OpenGame}?qs=${encrypt(queryString)}`);
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <div className="wrapped-content my-4">
      {dialogContextHolder}
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(154px,_1fr))] gap-4">
        {casinoProviderMapped.map((item) => (
          <ProviderCard
            className="w-full"
            logo={item.logo}
            hero={item.hero}
            key={item.id}
            onOpenGame={() => {
              handleOpenCasino(item);
            }}
            theme={item.meta?.theme}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default CasinoProviderListsContainer;
