import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog } from '.';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';

interface ErrorDialogProps {
  title: ReactNode;
  content: ReactNode;
  visible: boolean;
  onClose: () => void;
}

const ErrorDialog: FC<ErrorDialogProps> = (props) => {
  const { title, content, visible, onClose } = props;
  const { t } = useTranslation('buttons');
  return (
    <Dialog onClose={() => null} open={visible} size="xs">
      <div className="bg-dark flex w-full max-w-md flex-col gap-2 rounded-lg p-4">
        <p className="text-lg font-bold">{title}</p>
        {content}
        <Button
          block
          className="mt-4"
          color={ButtonColors.Danger}
          onClick={onClose}
        >
          {t('btn-close')}
        </Button>
      </div>
    </Dialog>
  );
};

export default ErrorDialog;
