import { FC, useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button } from '~components/button';
import { useSportsbook, useSportsbookInfoHelper } from '~hooks/use-sports-book';
import { ButtonColors, SizeTypes } from '~constants/components';
import { DEFAULT_CURRENCY, S3_BUCKET_IMAGES } from '~constants/etc';
import { ProviderSportResApiInterface } from 'types/sport';
import { ClientSideRender } from '~components/layouts/client-side-render';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';
import { onOpenSportURL } from '~libs/open-url';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { useDialog } from '~hooks/use-dialog';
import HttpErrorHandler from '~components/http-error-handler';
import Image from 'next/image';

const SportbookProvider: FC = () => {
  const { t } = useTranslation(['common']);
  const { data: sportProvider } = useSportsbook();
  const { getSportProviderImage } = useSportsbookInfoHelper();
  const containerRef = useRef(null);
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const [dialog, dialogContext] = useDialog();

  const sportMapped = useMemo(() => {
    if (!sportProvider) {
      return [];
    }
    return sportProvider.map((provider) => {
      const images = getSportProviderImage(provider.id);
      return {
        ...provider,
        horizontalLogo: images?.horizontalLogo,
        hero: `${S3_BUCKET_IMAGES}/recommend-hero-${provider.id}.webp`,
        background: `${S3_BUCKET_IMAGES}/recommend-background-${provider.id}.webp`,
      };
    });
  }, [sportProvider]);

  const handleOpenSportsbook = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <div className="wrapped-content my-6 flex flex-col gap-4">
      {dialogContext}
      <h1 className="md:text-2xl">{t('all-products-sports')}</h1>
      <motion.div
        className="flex flex-nowrap overflow-hidden"
        ref={containerRef}
      >
        <motion.div
          className="flex gap-4"
          drag="x"
          dragConstraints={containerRef}
        >
          {sportMapped?.map((item) => (
            <div
              className="relative min-h-[200px] w-[360px] overflow-hidden rounded-lg"
              key={item.id}
            >
              <Image
                alt="horizontalLogo"
                width={125}
                height={50}
                className="absolute left-6 top-6 h-[50px] w-[125px]"
                src={item.horizontalLogo || ''}
              />
              <Image
                alt="hero"
                width={183}
                height={200}
                className="absolute inset-y-0 right-8 h-[200px] w-[183px]"
                src={item.hero}
              />
              <Image
                alt="background"
                width={150}
                height={150}
                className="absolute inset-0 -z-30 h-full w-full object-cover"
                src={item.background}
              />
              <Image
                alt="sport-overlay"
                width={150}
                height={150}
                className="absolute inset-0 -z-20 h-full w-full object-cover"
                src={`${S3_BUCKET_IMAGES}/sport-overlay.webp`}
              />

              <Button
                className="bg- absolute bottom-4 left-6 z-[9] w-[112px] border border-white"
                color={ButtonColors.Transparent}
                size={SizeTypes.Small}
                onClick={() => handleOpenSportsbook(item)}
              >
                {t('common:bet-now')}
              </Button>
            </div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default SportbookProvider;
