import { useMemo } from 'react';
import ProviderCard from '~components/card/provider-card';
import PageUrls from '~constants/page-urls';
import { useAllGames, useGamingInfoHelper } from '~hooks/use-gaming';
import { useRouter } from '~hooks/use-router';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const GamingProviderListsContainer: React.FC = () => {
  const router = useRouter();
  const { data: gamingProvider } = useAllGames();
  const { getGamingProviderImage } = useGamingInfoHelper();

  const gamingProviderMapped = useMemo(() => {
    if (!gamingProvider) {
      return [];
    }
    return gamingProvider.map((provider) => {
      const images = getGamingProviderImage(provider.id);
      return {
        ...provider,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
      };
    });
  }, [gamingProvider]);

  return (
    <div className="wrapped-content my-4">
      <div className="grid grid-cols-[repeat(auto-fit,_minmax(154px,_1fr))] gap-4">
        {gamingProviderMapped.map((item) => {
          return (
            <ProviderCard
              className="w-full"
              logo={item.logo}
              hero={item.hero}
              key={item.id}
              onOpenGame={() => {
                router.push(`${PageUrls.Gaming}/${item.id}`);
              }}
              theme={item.meta?.theme}
              title={item.title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GamingProviderListsContainer;
