import Image from 'next/image';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import useIsMobile from '~libs/use-is-mobile';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const WhyPlayL68: React.FC = () => {
  const { t } = useTranslation(['home']);
  const isMobile = useIsMobile();
  const containerRef = useRef(null);

  return (
    <div className="relative mb-20 mt-20 h-[288px] sm:mb-28 xl:my-20">
      <div className="wrapped-content relative h-full">
        <div className="flex flex-col gap-5 pt-9 xl:ml-64">
          <p className="ml-[200px] text-xl font-bold sm:text-3xl xl:ml-0">
            {t('home:why-play.title')}{' '}
            <span className="text-primary">L68 Bet</span>
          </p>
          <motion.div
            className="flex cursor-pointer flex-nowrap overflow-hidden md:block md:cursor-default"
            ref={containerRef}
          >
            <motion.div
              className="flex gap-4 md:justify-end xl:justify-start"
              drag="x"
              dragConstraints={containerRef}
              dragListener={isMobile}
            >
              {[
                {
                  title: t('home:why-play.feature-1'),
                  description: t('home:why-play.feature-1-description'),
                },
                {
                  title: t('home:why-play.feature-2'),
                  description: t('home:why-play.feature-2-description'),
                },
                {
                  title: t('home:why-play.feature-3'),
                  description: t('home:why-play.feature-3-description'),
                },
              ].map((item, i) => {
                return (
                  <div
                    className="justoify-center flex min-h-[212px] w-[300px] flex-col items-center rounded-lg bg-white p-5 xl:w-full"
                    key={i}
                  >
                    <Image
                      alt=""
                      className="mx-auto"
                      height={120}
                      src={`${S3_BUCKET_IMAGES}/card-wpl68-${i + 1}.webp`}
                      width={120}
                    />
                    <div className="text-dark flex flex-col text-center font-bold">
                      <p className="text-sm sm:text-lg">{item.title}</p>
                      <p className="text-sm font-normal sm:text-base">
                        {item.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </motion.div>
          </motion.div>
        </div>
        <Image
          alt=""
          className="absolute bottom-0 left-auto -z-10"
          height={314}
          src={`${S3_BUCKET_IMAGES}/woman-bg-why-play-l68.webp`}
          width={242}
        />
      </div>
      <Image
        alt=""
        className="-z-20 object-cover"
        fill
        src={`${S3_BUCKET_IMAGES}/bg-why-play-l68.webp`}
      />
    </div>
  );
};

export default WhyPlayL68;
