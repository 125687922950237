'use client';

import { FC, useMemo } from 'react';
import type { ProviderSportResApiInterface } from 'types/sport';
import ProviderCard from '~components/card/provider-card';
import HttpErrorHandler from '~components/http-error-handler';
import { DEFAULT_CURRENCY, S3_BUCKET_IMAGES } from '~constants/etc';
import { useSportsbook, useSportsbookInfoHelper } from '~hooks/use-sports-book';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import { onOpenSportURL } from '~libs/open-url';
import { ClientSideRender } from '~components/layouts/client-side-render';
import UnauthorizedAlertWidget from '~components/widgets/unauthorized-alert-widget';
import { useDialog } from '~hooks/use-dialog';

const SportsbookProviderListsContainer: FC = () => {
  const { data: sportProvider } = useSportsbook();
  const { getSportProviderImage } = useSportsbookInfoHelper();
  const [dialog, dialogContextHolder] = useDialog();
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;

  const sportProviderMapped = useMemo(() => {
    if (!sportProvider) {
      return [];
    }
    return sportProvider.map((provider) => {
      const images = getSportProviderImage(provider.id);
      return {
        ...provider,
        logo: images?.logo,
        hero: `${S3_BUCKET_IMAGES}/providers/hero-${provider.id}.webp`,
      };
    });
  }, [sportProvider]);

  const handleOpenSportsbook = async (
    game: ProviderSportResApiInterface,
  ): Promise<void> => {
    if (!userInfo) {
      return dialog.content({
        children: (
          <ClientSideRender>
            <UnauthorizedAlertWidget onClose={() => dialog.destroy()} />
          </ClientSideRender>
        ),
      });
    }
    try {
      const currencyFiltered = game.currencies
        .filter((x) => x.includes(currency))
        .at(0);
      window.open(
        await onOpenSportURL(game.id, currencyFiltered, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  return (
    <div className="wrapped-content my-4">
      {dialogContextHolder}
      <div className="grid grid-cols-2 gap-4">
        {sportProviderMapped.map((item) => (
          <ProviderCard
            className="w-full"
            logo={item.logo}
            hero={item.hero}
            key={item.id}
            onOpenGame={async () => {
              await handleOpenSportsbook(item);
            }}
            theme={item.meta?.theme}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};

export default SportsbookProviderListsContainer;
