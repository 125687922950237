'use client';

import { useRouter as useNextRouter } from 'next/dist/client/components/navigation';
import { usePathname } from 'next/navigation';
import { parseCookies } from 'nookies';
import NProgress from 'nprogress';
import checkSameUrl from '~libs/check-same-url';

export function useRouter() {
  const router = useNextRouter();
  const pathname = usePathname();
  const cookies = parseCookies();
  const locale = cookies.NEXT_LOCALE || '';
  const regexLocale = new RegExp(`^/${locale}`);

  function push(
    href: string,
    options?: Record<string, any> | undefined,
    NProgressOptions?: { showProgressBar?: boolean },
  ): void {
    if (NProgressOptions?.showProgressBar === false) {
      router.push(href, options);
      return;
    }
    let currentUrl = new URL(pathname, location.href);
    let targetUrl = new URL(href, location.href);
    currentUrl = {
      ...currentUrl,
      href: `${currentUrl.origin}${currentUrl.pathname.replace(
        regexLocale,
        '',
      )}`,
    };
    if (!targetUrl.search) {
      targetUrl = {
        ...targetUrl,
        href: `${targetUrl.origin}${targetUrl.pathname.replace(
          regexLocale,
          '',
        )}`,
      };
    }
    const isSameUrl = checkSameUrl(currentUrl.href, targetUrl.href);
    if (isSameUrl) return;
    NProgress.start();
    router.push(href, options);
  }

  function back(NProgressOptions?: { showProgressBar?: boolean }): void {
    if (NProgressOptions?.showProgressBar === false) {
      router.back();
      return;
    }

    NProgress.start();

    router.back();
  }

  return { ...router, push, back };
}
