import { motion } from 'framer-motion';
import { FC, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
import useIsMobile from '~libs/use-is-mobile';
import { useCasinos } from '~hooks/use-casinos';
import { useAllGames } from '~hooks/use-gaming';
import { useSportsbook } from '~hooks/use-sports-book';
import getImageList from '~libs/get-image-list';

const CustomImage: FC<{
  src: string;
}> = ({ src }) => {
  const [isError, setIsError] = useState<boolean>(false);
  if (isError) {
    return;
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="bg-dark relative flex h-[65px] w-[160px] items-center justify-center rounded-lg">
        <Image
          alt="logo"
          height={72}
          src={src}
          width={100}
          onError={() => setIsError(true)}
        />
      </div>
    </div>
  );
};

const OurServiceProviders: React.FC = () => {
  const { t } = useTranslation(['home']);
  const isMobile = useIsMobile();
  const containerRef = useRef(null);
  const { data: casinoProvider } = useCasinos();
  const { data: gamingProvider } = useAllGames();
  const { data: sportsbookProvider } = useSportsbook();

  const providerMapped = useMemo(() => {
    const allProviders = [
      ...(casinoProvider || []),
      ...(gamingProvider || []),
      ...(sportsbookProvider || []),
    ];
    return allProviders.map((res) => {
      const images = getImageList(res.images);
      return {
        horizontalLogo: images.horizontalLogo,
      };
    });
  }, [casinoProvider, gamingProvider, sportsbookProvider]);

  return (
    <div className="wrapped-content my-6 flex w-full flex-col gap-4 xl:my-10">
      <h1 className="md:text-2xl">{t('home:providers.title')}</h1>
      <motion.div
        className="flex cursor-pointer flex-nowrap overflow-hidden md:block md:cursor-default"
        ref={containerRef}
      >
        <motion.div
          className="flex gap-4 md:grid md:grid-cols-[repeat(auto-fit,_minmax(166px,_1fr))]"
          drag="x"
          dragConstraints={containerRef}
          dragListener={isMobile}
        >
          {providerMapped.map((item, i) => (
            <CustomImage src={item.horizontalLogo} key={i} />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default OurServiceProviders;
