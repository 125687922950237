'use client';

import {
  CasinoFilled,
  HomeFilled,
  SlotFilled,
  SportFilled,
} from '@packages/icons-react';
import { useMemo, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { Button } from '~components/button';
import { ButtonColors } from '~constants/components';
import CustomCarousel from '~containers/home/custom-carousel';
import OurServiceProviders from '~containers/home/our-service-providers';
import PopularProviders from '~containers/home/popular-providers';
import SportbookProvider from '~containers/home/sportbook-provider';
import WhyPlayL68 from '~containers/home/why-play-l68';
import useIsMobile from '~libs/use-is-mobile';
import SportsbookProviderListsContainer from '~containers/providers/sportsbook-provider-lists-container';
import GamingProviderListsContainer from '~containers/providers/gaming-provider-lists-container';
import CasinoProviderListsContainer from '~containers/providers/casino-provider-lists-container';
import MiniGameProviderListsContainer from '~containers/providers/mini-game-provider-lists-container';
import { ProviderEnum } from '~constants/menus';

const HomeClientPage: FC = () => {
  const { t } = useTranslation(['common']);
  const containerRef = useRef(null);
  const isMobile = useIsMobile();
  const [tab, setTab] = useState('home');

  const providerRender = useMemo(() => {
    switch (tab) {
      case ProviderEnum.SPORT:
        return <SportsbookProviderListsContainer />;
      case ProviderEnum.CASINO:
        return <CasinoProviderListsContainer />;
      case ProviderEnum.GAMING:
        return <GamingProviderListsContainer />;
      case ProviderEnum.MINI_GAME:
        return <MiniGameProviderListsContainer />;
      default:
        return '';
    }
  }, [tab]);

  return (
    <>
      <div className="wrapped-content mb-4 mt-2 md:hidden">
        <motion.div
          className="flex cursor-pointer flex-nowrap overflow-hidden"
          ref={containerRef}
        >
          <motion.div
            className="flex gap-1"
            drag="x"
            dragConstraints={containerRef}
          >
            {[
              { key: 'home', title: t('menu.home'), icon: <HomeFilled /> },
              {
                key: ProviderEnum.SPORT,
                title: t('menu.sportsbook'),
                icon: <SportFilled />,
              },
              {
                key: ProviderEnum.CASINO,
                title: t('menu.casino'),
                icon: <CasinoFilled />,
              },
              {
                key: ProviderEnum.GAMING,
                title: t('menu.gaming'),
                icon: <SlotFilled />,
              },
            ].map((item, i) => {
              return (
                <Button
                  color={
                    tab === item.key
                      ? ButtonColors.Primary
                      : ButtonColors.Default
                  }
                  key={i}
                  className="w-full min-w-[150px]"
                  onClick={() => {
                    setTab(item.key);
                  }}
                >
                  <span className="text-2xl">{item.icon}</span>
                  <span>{item.title}</span>
                </Button>
              );
            })}
          </motion.div>
        </motion.div>
      </div>

      {tab === 'home' || !isMobile ? (
        <>
          <CustomCarousel />
          <SportbookProvider />
          <PopularProviders />
          {/*<RecommendedSpecialGames />*/}
          <WhyPlayL68 />
          <OurServiceProviders />
        </>
      ) : (
        providerRender
      )}
    </>
  );
};

export default HomeClientPage;
