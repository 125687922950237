'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules';
import { cn } from 'class-merge';
import { list } from 'radash';
import { S3_BUCKET_IMAGES } from '~constants/etc';

const CustomCarousel: React.FC = () => {
  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      className={cn(
        'relative z-0',
        'before:absolute before:inset-x-0 before:bottom-0 before:z-10 before:h-20',
        'before:from-layout before:bg-gradient-to-t before:to-transparent',
      )}
      id="custom-carousel"
      loop
      modules={[Pagination, Autoplay]}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={0}
    >
      {list(4).map((i) => {
        return (
          <SwiperSlide key={i}>
            <div
              className="relative min-h-[140px] w-full bg-cover bg-left bg-no-repeat md:min-h-[417px] 2xl:min-h-[520px] 2xl:bg-bottom"
              style={{
                backgroundImage: `url(${S3_BUCKET_IMAGES}/carousel-${i + 1}.webp)`,
              }}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CustomCarousel;
